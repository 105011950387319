/* Font Import statements */
/* Snapping */
/* html {scroll-snap-type: y mandatory} */

body {
  padding: 0;
  margin: 0;
  background: #fffbf8;
}

* {
  box-sizing: border-box;
  /* font-family: Helvetica, sans-serif;
  font-weight: 900; */
  color: #222;
}

.gallery {
  /* padding: 40px; */
  /* margin: 0 auto; */
  /* max-width: 1200px; */
  /* min-width: 100%; */
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  min-width: 100vw;
  overflow-y: visible;
  overflow-x: hidden;
  scroll-margin-bottom: 20vh;
}
/* .galleryRow{
  width: 100%;
} */
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.thumbnail {
  flex: 1 0 50%;
  /* margin: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame {
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 100px;
  text-align: center;
  position: fixed;
  bottom: -100px;
  z-index: 1;
  color: #000;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.single {
  overflow: visible;
  /* min-height: 100vh; */
  /* height: 100vh;  */
  /* Image at 100 causes weird issue */
}

.single img {
  width: 100%;
  /* max-height: 80vh; */
}

.back {
  position: fixed;
  top: 50px;
  right: 50px;
  font-size: 2rem;
  z-index: 1;
  color: #000;
  font-family: "Catamaran", sans-serif;
  font-weight: 400;
}

.back a {
  text-decoration: none;
  color: #000;
  font-family: "Catamaran", sans-serif;
  font-weight: 400;
}
/* Overriding bootstrap below this */

.About a {
  text-decoration: none;
  color: #000;
}
.logo {
  width: 10%;
  height: 10%;
  padding-bottom: 5%;
}
.logo img{
  width: 100%;
}
.Contact a {
  text-decoration: none;
  color: #000;
}

#AboutContainer {
  margin: 0;
  min-width: 100%;
  position: sticky;
  overflow: hidden;
  font-family: "Catamaran", sans-serif;
  font-weight: 550;
  scroll-snap-align: start;
}
#HistoryContainer {
  margin: 0;
  min-width: 100%;
  position: sticky;
  overflow: hidden;
  font-family: "Catamaran", sans-serif;
  font-weight: 550;
  scroll-snap-align: start;
}
#ContactContainer {
  margin: 0;
  min-width: 100%;
  position: sticky;
  overflow: hidden;
  font-family: "Catamaran", sans-serif;
  font-weight: 550;
  scroll-snap-align: start;
}
.galleryRow a {
  text-decoration: none;
}
.flexy {
  display: flex;
  align-items: flex-end;
}

/* What you see when you click into a project. */
#DetailContainer {
  min-width: 100%;
  height: 100vh;

  margin: 0;
  font-family: "Catamaran", sans-serif;
  font-weight: 550;
  scroll-snap-align: start;
}
.Detail a {
  text-decoration: none;
}

.footnote {
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 200;
  text-align: left;
}
.footnote-alt {
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 200;
  text-align: left;
}

/* History Section */

/* Calendly */
.calendly-badge-widget {
  width: 6rem !important;
  height: 6rem !important;
  padding: 0.5rem;
  background-color: #FEFBF8;
  border: 2px solid #1D35E4 !important; /* Set the initial border */
  box-sizing: border-box; /* Include border in the element's dimensions */
  transition: border-width 0s ease; /* Add transition property for smooth effect */
  margin: -1px;
}

.calendly-badge-widget:hover {
  margin: 0px;

  border-width: 3px !important; /* Increase the border width on hover */
}

.calendly-badge-widget a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #1D35E4 !important;
  text-decoration: none;
  line-height: 120%;
  white-space: normal; /* Allow text to wrap */
  font-family: "Catamaran", sans-serif;
}

/* Calendly end */
/* Responsive below */
@media screen and (min-width: 300px) {
  h1 {
    font-size: 140px;
    bottom: -130px;
  }

  /* About responsive */
  #AboutContainer {
    padding-top: 5rem;
    padding-left: 1.2rem;
    padding-right: 3.5rem;
  }
  #ContactContainer {
    /* padding-top: 5rem; */
    padding-left: 1.2rem;
    padding-right: 3.5rem;
  }
  #HistoryContainer {
    padding-top: 8rem;
    padding-left: 1.2rem;
    padding-right: 3.5rem;
  }

  .spacer {
    padding-top: 6vh;
  }
  /* Gallery responsive */
  .gallery {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 8rem;
    /* margin: 0 auto; */
  }
  .galleryRow {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .thumbnailtext {
    padding-top: 0.3rem;
    /* padding-left: 2vw; */
    /* padding-right: 6vw; */
    font-family: "Catamaran", sans-serif;

    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    /* letter-spacing: -0.01rem; */
  }
  .catchphrasetext {
    /* padding-left: 2vw; */
    /* padding-right: 6vw; */
    font-family: "Catamaran", sans-serif;

    padding-top: 0.43rem;
    font-size: 0.8rem;
    font-weight: 200;
    line-height: 0.9rem;
    letter-spacing: 0rem;
  }

  .marquee-scroll {
    letter-spacing: 0.09rem;
    padding-left: 5px;
    padding-right: 5px;
  }

  /* Contact section responsive */

  /* About responsive */

  .AboutDescription {
    font-size: 2.2rem;
    line-height: 2.2rem;
    letter-spacing: 0.15px;
  }

  .AboutClients{
    padding-top: 12vh;
    font-weight: 300;
    letter-spacing: 3px;
  }
  .AboutBanner img{
    width: 50vw;
    padding-top: 3vh;
  }
  .footnote {
    padding-left: 0vw;
    padding-right: 15vw;
    padding-top: 2vh;
  }

  #profile-img-spacer{
    padding-top: 0vh;
  }
  #profile-img{
    padding-bottom: 0.6rem;
    width: 45vw;
  }
  .footnote-alt {
    padding-left: 0vw;
    padding-right: 15vw;
    padding-top: 4vh;
  }
  #footnoteexception{
    padding-left: 0vw;
  
  }

  #servicesfootnote{
    padding-left: 0vw;
    padding-right: 0vw;
  
    font-family: "Catamaran", sans-serif;
  
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 1.65rem;
    letter-spacing: 0rem;
    padding-top: 0.0rem;
  
  }
  
  #servicesfootnote{
    padding-left: 0vw;
  
  }


  .super {
    font-size: 1.2rem;
    font-weight: 200;
    letter-spacing: 0rem;
    vertical-align: super;
    line-height: 1rem;
  }
  #cheeky {
    font-size: 1.5rem;
    font-weight: 200;
  }
  /* Marquee */
  #edgetoedge {
    min-width: 100vw;
    padding-left: 2vw;
    padding-right: 2vw;
    position: absolute;
    width: 100%;
    bottom: 2rem;
  }
  .marquee {
    border-top: 1.8px solid #000;
    border-bottom: 1.8px solid #000;
  }
  .marquee2 {
    border-bottom: 1.8px solid #000;
  }
  .marquee-scroll a {
    color: rgba(0, 0, 0, 0);
    font-size: 1.8rem;
    -webkit-text-stroke: 1.2px black;
    font-weight: 1000;
    font-family: "Catamaran", sans-serif;
    text-transform: uppercase;
    text-decoration: none;

  }
  .underline {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
  .contact-line {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
  .supercontainer {
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
    padding-top: 10rem;
  }
  /* Project Pages */
  #DetailContainer {
    padding-top: 2rem;
    padding-left: 1.2rem;
    padding-right: 2rem;
  }
  #ProjectTitle {
    font-size: 2.2rem;
    line-height: 2.2rem;
    letter-spacing: 0.15px;
    font-weight: 550;
    color: #000000;
    font-family: "Catamaran", sans-serif;
  }
  #Tagline {
    font-size: 1.4rem;
    font-weight: 300;
    font-family: "Lato";
    font-style: italic;
    /* padding-top: 0.5rem; */
  }
  #summaryrow {
    padding-top: 4.5rem;
  }

  .line {
    border-bottom: 1px solid #000000;
    padding-top: 7rem;
  }
  .projectText {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  .label {
    font-weight: 400;
    font-size: 1.3rem;
    color: #000000;
    
    /* font-weight: bold; */
  }
  .description {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 100;
  }
  .spacerOutcome{
    padding-top: 4rem;
  }
  #ImageDescription {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: right; /*saumya's ???*/
    font-size: 1rem;
    /* padding-left: 0rem; */
    /* padding-right: 0.1rem; */
    color: rgb(87, 87, 87);
    display: none;
  }
  #ImageDescriptionMDbelow {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: left; /*saumya's ???*/
    font-size: 0.9rem;
    line-height: 17px;
    /* padding-right: 1rem; */
    padding-top: 0.3rem;
    color: rgb(87, 87, 87);
    display: block;
  }

  .ProjectImage {
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
  .body-text {
    font-size: 1.2rem;
    font-weight: 200;
    /* line-height: 1.38rem; */
  }
  #section-heading {
    font-size: 2rem;
    font-weight: 200;
  }
}

/* bootstrap - medium  */
@media screen and (min-width: 768px) {
  h1 {
    font-size: 180px;
    bottom: -170px;
  }
  /* About responsive */
  #AboutContainer {
    padding-top: 7rem;
    padding-left: 5rem;
  }
  #ContactContainer {
    padding-top: 5rem;
    padding-left: 5rem;
  }
  #HistoryContainer {
    padding-top: 10rem;
    padding-left: 5rem;
  }

  .spacer {
    padding-top: 50vh;
  }
  /* Gallery responsive */
  .gallery {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 3rem;
    /* margin: 0 auto; */
  }
  .galleryRow {
    padding-bottom: 1rem;
    padding-top: 6rem;
  }
  .thumbnailtext {
    padding-top: 0.3rem;
    /* padding-left: 2vw; */
    /* padding-right: 6vw; */
    font-family: "Catamaran", sans-serif;

    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.8rem;
    /* letter-spacing: -0.03rem; */
  }
  .catchphrasetext {
    /* padding-left: 2vw; */
    /* padding-right: 6vw; */
    font-family: "Catamaran", sans-serif;

    padding-top: 1.3rem;
    font-size: 0.8rem;
    font-weight: 200;
    line-height: 0.9rem;
    letter-spacing: 0rem;
    text-transform: uppercase;
  }

  .marquee-scroll {
    letter-spacing: 0.09rem;
  }
  /* Contact section responsive */

  /* About responsive */

  .AboutDescription {
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: -0.1rem;
  }
  .AboutClients{
    padding-top: 12vh;
    font-weight: 300;
    letter-spacing: 3px;
  }
  .AboutBanner img{
    width: 30vw;
    padding-top: 3vh;
  }

  .footnote {
    padding-left: 0vw;
    padding-right: 8vw;
    padding-top: 4vh;
  }

  #profile-img-spacer{
    padding-top: 20vh;
  }
  #profile-img{
    padding-bottom: 0.6rem;
    width: 18vw;
  }
  .footnote-alt {
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 4vh;
    width: 18vw;
  }
#footnoteexception{
  padding-left: 0vw;

}

#servicesfootnote{
  padding-left: 0vw;
  padding-right: 0vw;

  font-family: "Catamaran", sans-serif;

  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.65rem;
  letter-spacing: 0rem;
  padding-top: 0.0rem;

}

#servicesfootnote{
  padding-left: 0vw;

}


  .super {
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    vertical-align: super;
  }
  #cheeky {
    font-size: 3rem;
    font-weight: 200;
  }
  /* Marquee */
  #edgetoedge {
    min-width: 100vw;
    padding-left: 2vw;
    padding-right: 2vw;
    position: absolute;
    width: 100%;
    bottom: 1rem;
  }
  .marquee {
    border-top: 1.8px solid #000;
    border-bottom: 1.8px solid #000;
  }
  .marquee2 {
    border-bottom: 1.8px solid #000;
  }
  .marquee-scroll a {
    color: rgba(0, 0, 0, 0);
    font-size: 2.3rem;
    -webkit-text-stroke: 1.8px black;
    font-weight: 1000;
    font-family: "Catamaran", sans-serif;
    text-transform: uppercase;
    text-decoration: none;

  }
  .underline {
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }
  .contact-line {
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }
  .supercontainer {
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
    padding-top: 0rem;

  }
  /* Project Pages */
  #DetailContainer {
    padding-left: 5rem;
    padding-top: 5rem;
    /* padding-right: 7rem; */
  }
  #ProjectTitle {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 550;
    color: #000000;
    font-family: "Catamaran", sans-serif;
    letter-spacing: -0.1rem;
  }
  #Tagline {
    font-size: 1.8rem;
    font-weight: 300;
    font-family: "Lato";
    font-style: italic;
    padding-top: 0.5rem;
  }
  #summaryrow {
    padding-top: 4.5rem;
  }

  .line {
    border-bottom: 1px solid #000000;
    padding-top: 14rem;
  }
  .projectText {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  .label {
    font-weight: 400;
    font-size: 1.3rem;
    color: #000000;

    /* font-weight: bold; */
  }
  .description {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 100;
  }
  #ImageDescription {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: right; /*saumya's ???*/
    font-size: 1rem;
    /* padding-left: 0rem; */
    /* padding-right: 0.1rem; */
    color: rgb(87, 87, 87);
    display: none;
  }
  #ImageDescriptionMDbelow {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: left; /*saumya's ???*/
    font-size: 1rem;
    /* padding-right: 1rem; */
    padding-top: 0.5rem;
    color: rgb(87, 87, 87);
    display: block;
  }

  .ProjectImage {
    padding-right: 4.2rem;
    padding-bottom: 4rem;
  }
  .body-text {
    font-size: 1.4rem;
    font-weight: 200;
    /* line-height: 1.38rem; */
  }
  #section-heading {
    font-size: 2rem;
    font-weight: 200;
  }
}

/* Bootstrap- large */
@media screen and (min-width: 992px) {
  h1 {
    font-size: 220px;
    bottom: -200px;
  }
  /* About responsive */
  #AboutContainer {
    padding-top: 7rem;
    padding-left: 5rem;
  }
  #ContactContainer {
    padding-top: 5rem;
    padding-left: 5rem;
  }
  #HistoryContainer {
    padding-top: 10rem;
    padding-left: 5rem;
  }

  .spacer {
    padding-top: 50vh;
  }
  /* Gallery responsive */
  .gallery {
    padding-left: 5rem;
    /* padding-right: 7rem;*/
    padding-top: 3rem;
    /* margin: 0 auto; */
  }
  .galleryRow {
    padding-bottom: 1rem;
    padding-top: 3rem;
  }
  .thumbnailtext {
    padding-top: 1rem;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: "Catamaran", sans-serif;

    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.8rem;
    /* letter-spacing: -0.03rem; */
  }
  .catchphrasetext {
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: "Catamaran", sans-serif;

    font-size: 0.8rem;
    font-weight: 200;
    line-height: 0.9rem;
    letter-spacing: 0rem;
    text-transform: uppercase;
  }

  .marquee-scroll {
    letter-spacing: 0.09rem;
  }
  /* Contact section responsive */

  /* About responsive */

  .AboutDescription {
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: -0.1rem;
  }
  .AboutClients{
    padding-top: 12vh;
    font-weight: 300;
    letter-spacing: 3px;
  }
  .AboutBanner img{
    width: 30vw;
    padding-top: 3vh;
  }

  .footnote {
    /* padding-left: 5vw; */
    padding-right: 7vw;
    padding-top: 4vh;
  }

  #profile-img-spacer{
    padding-top: 20vh;
  }
  #profile-img{
    padding-bottom: 0.6rem;
  }
  .footnote-alt {
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 4vh;
  }
  #footnoteexception{
    padding-left: 0vw;
  
  }

  #servicesfootnote{
    padding-left: 0vw;
    padding-right: 0vw;

    font-family: "Catamaran", sans-serif;

    font-size: 1.4rem;
    font-weight: 200;
    line-height: 1.65rem;
    letter-spacing: 0rem;
    padding-top: 0.0rem;
  
  }

  #servicesfootnote{
    padding-left: 0vw;
  
  }


  .super {
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    vertical-align: super;
  }
  #cheeky {
    font-size: 3rem;
    font-weight: 200;
  }
  /* Marquee */
  #edgetoedge {
    min-width: 100vw;
    padding-left: 2vw;
    padding-right: 2vw;
    position: absolute;
    width: 100%;
    bottom: 1rem;
  }
  .marquee {
    border-top: 1.8px solid #000;
    border-bottom: 1.8px solid #000;
  }
  .marquee2 {
    border-bottom: 1.8px solid #000;
  }
  .marquee-scroll a {
    color: rgba(0, 0, 0, 0);
    font-size: 2.3rem;
    -webkit-text-stroke: 1.8px black;
    font-weight: 1000;
    font-family: "Catamaran", sans-serif;
    text-transform: uppercase;
    text-decoration: none;

  }

  .underline {
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }
  .contact-line {
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }
  .supercontainer {
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
    padding-top: 0rem;

  }
  /* Project Pages */
  #DetailContainer {
    padding-left: 7rem;
    padding-top: 7rem;
    /* padding-right: 7rem; */
  }
  #ProjectTitle {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 550;
    color: #000000;
    font-family: "Catamaran", sans-serif;
    letter-spacing: -0.1rem;
  }
  #Tagline {
    font-size: 1.8rem;
    font-weight: 300;
    font-family: "Lato";
    font-style: italic;
    padding-top: 0.5rem;
  }
  #summaryrow {
    padding-top: 4.5rem;
  }

  .line {
    border-bottom: 1px solid #000000;
    padding-top: 14rem;
  }
  .projectText {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  .label {
    font-weight: 400;
    font-size: 1.6rem;
    color: #000000;

    /* font-weight: bold; */
  }
  .description {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 100;
  }
  #ImageDescription {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: right; /*saumya's ???*/
    font-size: 1rem;
    /* padding-left: 0rem; */
    /* padding-right: 0.1rem; */
    color: rgb(87, 87, 87);
    display: block;
  }
  #ImageDescriptionMDbelow {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: right; /*saumya's ???*/
    font-size: 1rem;
    padding-left: 4rem;
    padding-right: 1rem;
    color: rgb(87, 87, 87);
    display: none;
  }

  .ProjectImage {
    padding-right: 5rem;
    padding-bottom: 8rem;
  }
  .body-text {
    font-size: 1.4rem;
    font-weight: 200;
    /* line-height: 1.38rem; */
  }
  #section-heading {
    font-size: 2rem;
    font-weight: 200;
  }
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 280px;
    bottom: -260px;
  }
  /* About responsive */
  #AboutContainer {
    /* padding-top: 10rem; */
    /* Remove all the AboutContainer padding to resolve project image display. */
    padding-left: 7rem;
  }
  #ContactContainer {
    padding-top: 10rem;
    padding-left: 7rem;
  }
  #HistoryContainer {
    padding-top: 10rem;
    padding-left: 7rem;
  }

  .spacer {
    padding-top: 50vh;
  }
  /* Gallery responsive */
  .gallery {
    padding-left: 7rem;
    /* padding-right: 7rem;*/
    padding-top: 3rem;
    /* margin: 0 auto; */
  }
  .galleryRow {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  .thumbnailtext {
    padding-top: 1rem;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: "Catamaran", sans-serif;

    font-size: 2rem;
    font-weight: 500;
    line-height: 2.1rem;
    /* letter-spacing: -0.1rem; */
  }
  .catchphrasetext {
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: "Catamaran", sans-serif;

    font-size: 1.2rem;
    font-weight: 200;
    line-height: 1.2rem;
    letter-spacing: 0rem;
    text-transform: uppercase;
  }

  .marquee-scroll {
    letter-spacing: 0.09rem;
  }
  /* Contact section responsive */

  /* About responsive */

  .AboutDescription {
    font-size: 5.8rem;
    line-height: 5.8rem;
    letter-spacing: -0.1rem;
  }
  .AboutClients{
    padding-top: 12vh;
    font-weight: 300;
    letter-spacing: 3px;
  }
  .AboutBanner img{
    width: 30vw;
    padding-top: 3vh;
  }

  .footnote {
    padding-left: 5vw;
    padding-right: 7vw;
    padding-top: 4vh;
  }
  #profile-img-spacer{
    padding-top: 20vh;
  }
  #profile-img{
    padding-bottom: 0.6rem;
    padding-right: 0vw;
    width: 100%;
  }
  .footnote-alt {
    /* padding-left: 5vw; */
    padding-right: 0vw;
    padding-top: 4vh;
    width: 100%;
  }
  #footnoteexception{
    padding-left: 0vw;
  
  }
  #servicesfootnote{
    padding-left: 0vw;
    padding-right: 0vw;

    font-family: "Catamaran", sans-serif;

    font-size: 1.6rem;
    font-weight: 200;
    line-height: 1.9rem;
    letter-spacing: 0rem;
    padding-top: 0.0rem;
  
  }


  .super {
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    vertical-align: super;
  }
  #cheeky {
    font-size: 3rem;
    font-weight: 200;
  }
  /* Marquee */
  #edgetoedge {
    min-width: 100vw;
    padding-left: 2vw;
    padding-right: 2vw;
    position: absolute;
    width: 100%;
    bottom: 1rem;
  }
  .marquee {
    border-top: 1.8px solid #000;
    border-bottom: 1.8px solid #000;
  }
  .marquee2 {
    border-bottom: 1.8px solid #000;
  }
  .marquee-scroll a {
    color: rgba(0, 0, 0, 0);
    font-size: 2.3rem;
    -webkit-text-stroke: 1.8px black;
    font-weight: 1000;
    font-family: "Catamaran", sans-serif;
    text-transform: uppercase;
    text-decoration: none;

  }

  .underline {
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }
  .contact-line {
    text-decoration-line: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }

  .supercontainer {
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
    padding-top: 0rem;

  }

  /* Project Pages */
  #DetailContainer {
    padding-left: 7rem;
    padding-top: 7rem;
    /* padding-right: 7rem; */
  }
  #ProjectTitle {
    font-size: 5.8rem;
    font-weight: 550;
    line-height: 5.8rem;
    color: #000000;
    font-family: "Catamaran", sans-serif;
    letter-spacing: -0.1rem;
  }
  #Tagline {
    font-size: 2.2rem;
    font-weight: 300;
    font-family: "Lato";
    font-style: italic;
    padding-top: 0.5rem;
  }
  #summaryrow {
    padding-top: 4.5rem;
  }

  .line {
    border-bottom: 1px solid #000000;
    padding-top: 14rem;
  }
  .projectText {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  .label {
    font-weight: 400;
    font-size: 1.6rem;
    color: #000000;

    /* font-weight: bold; */
  }
  .description {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 100;
  }
  #ImageDescription {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: right; /*saumya's ???*/
    font-size: 1rem;
    padding-left: 4rem;
    padding-right: 1rem;
    color: rgb(87, 87, 87);
    display: block;
  }
  #ImageDescriptionMDbelow {
    font-family: "Lato"; /*saumya's*/
    font-style: italic; /* saumya's*/
    text-align: right; /*saumya's ???*/
    font-size: 1rem;
    padding-left: 4rem;
    padding-right: 1rem;
    color: rgb(87, 87, 87);
    display: none;
  }

  .ProjectImage {
    padding-right: 7rem;
    padding-bottom: 8rem;
  }
  .body-text {
    font-size: 1.4rem;
    font-weight: 200;
    /* line-height: 1.38rem; */
  }
  #section-heading {
    font-size: 2rem;
    font-weight: 200;
  }
}
